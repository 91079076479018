import React from 'react'
import { colors } from '../../../styles/vars/colors.style'

const IconStar = ({ width = 20, height = 19, responsive = true }) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 19"
      aria-hidden="true"
      focusable="false"
      fill="none"
      aria-label="Star"
      {...svgAttributes}
    >
      <path
        fill={colors.green}
        d="m10 0 3.4 5.83L20 7.257l-4.5 5.031.68 6.712L10 16.28 3.82 19l.68-6.712L0 7.258 6.6 5.83 10 0Z"
      />
    </svg>
  )
}

export default IconStar
