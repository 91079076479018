import React, { useState, useEffect, useRef } from 'react'
import gsap from 'gsap'
import PropTypes from 'prop-types'
import { useSwipeable } from 'react-swipeable'
import {
  Feature,
  FeaturesBackground,
  FeaturesContent,
  FeaturesDivider,
  FeaturesDot,
  FeaturesDots,
  FeaturesImage,
  FeaturesImageDots,
  FeaturesMain,
  FeaturesSlider,
  FeaturesSliderInner,
} from './index.style'
import Spacer from '../../Spacer'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import Dot from '../../svgs/Dot'
import { GatsbyImage } from 'gatsby-plugin-image'
import AnimateSlideIn from '../../animation/AnimateSlideIn'
import AnimateFadeIn from '../../animation/AnimateFadeIn'
import { Heading4, TextBodyLarge } from '../../TextStyles'
import AnimateSplitText from '../../animation/AnimateSplitText'
import { SliderDotsButton, SliderDotsItem } from '../../Slider/index.style'
import { useStore } from '../../../Store'
import Dots from '../../Dots'

const Features = ({ items, image }) => {
  const [store] = useStore()
  const $slider = useRef()
  const $slides = useRef([])
  const [activeIndex, setActiveIndex] = useState(0)
  const [activeDotIndex, setActiveDotIndex] = useState(0)
  const [activeTextIndex, setActiveTextIndex] = useState(null)

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
      updateSlide(nextIndex)
    },
    onSwipedRight: () => {
      const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
      updateSlide(nextIndex)
    },
  })

  gsap.defaults({
    duration: 0.3,
    ease: 'power2.inOut',
  })

  const updateSlide = slideIndex => {
    if (slideIndex === activeIndex) return

    setActiveDotIndex(slideIndex)
    setActiveTextIndex(null)
    gsap.delayedCall(0.5, () => {
      setActiveTextIndex(slideIndex)
      setActiveIndex(slideIndex)
    })
  }

  // Delay starting the carousel animation until the font is loaded and splitText has run
  useEffect(() => {
    if (store.fontIsLoaded) {
      gsap.delayedCall(0.2, () => {
        setActiveTextIndex(0)
        gsap.set($slider.current, { opacity: 1 })
      })
    }
  }, [store.fontIsLoaded])

  // Setup the timeline
  useEffect(() => {
    const setSliderHeight = () => {
      const tallestSlide = $slides.current.reduce((prevSlide, currentSlide) => {
        return prevSlide.offsetHeight > currentSlide.offsetHeight
          ? prevSlide
          : currentSlide
      })

      tallestSlide.style.position = 'relative'
      tallestSlide.style.top = 0
      tallestSlide.style.transform = 'translateY(0)'
    }

    setSliderHeight()
  }, [])

  return (
    <Container>
      <FeaturesMain {...swipeHandlers}>
        <AnimateFadeIn>
          <FeaturesBackground />
        </AnimateFadeIn>

        <FeaturesDot>
          <AnimateSlideIn>
            <Dot />
          </AnimateSlideIn>
        </FeaturesDot>

        <FeaturesContent>
          <Grid>
            <GridItem tabletP={8}>
              <FeaturesImage>
                <FeaturesImageDots>
                  <Dots />
                </FeaturesImageDots>

                <AnimateFadeIn>
                  <GatsbyImage
                    image={image.gatsbyImageData}
                    alt={image.description}
                  />
                </AnimateFadeIn>

                <FeaturesDivider />
              </FeaturesImage>
            </GridItem>

            <GridItem tabletP={8} desk={5} deskStart={10}>
              <FeaturesSlider ref={$slider}>
                <FeaturesSliderInner>
                  {React.Children.toArray(
                    items.map((item, itemIndex) => (
                      <Feature
                        ref={ref => ($slides.current[itemIndex] = ref)}
                        active={activeIndex === itemIndex}
                        aria-hidden={activeIndex !== itemIndex}
                      >
                        <Heading4>
                          <AnimateSplitText
                            animate={activeTextIndex === itemIndex}
                          >
                            {item.title}
                          </AnimateSplitText>
                        </Heading4>

                        <Spacer size={[30, 35]} />

                        <TextBodyLarge>
                          <AnimateSplitText
                            animate={activeTextIndex === itemIndex}
                          >
                            {item.text.text}
                          </AnimateSplitText>
                        </TextBodyLarge>
                      </Feature>
                    ))
                  )}
                </FeaturesSliderInner>
              </FeaturesSlider>
            </GridItem>
          </Grid>
        </FeaturesContent>

        <FeaturesDots>
          {React.Children.toArray(
            items.map((item, itemIndex) => (
              <SliderDotsItem>
                <SliderDotsButton
                  active={activeDotIndex === itemIndex}
                  onClick={() => updateSlide(itemIndex)}
                >
                  {itemIndex}
                </SliderDotsButton>
              </SliderDotsItem>
            ))
          )}
        </FeaturesDots>
      </FeaturesMain>
    </Container>
  )
}

Features.propTypes = {
  items: PropTypes.array,
  image: PropTypes.object,
}

export default Features
