import React from 'react'
import PropTypes from 'prop-types'
import { StatsMain } from './index.style'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import Stat from '../Stat'

const Stats = ({ items }) => (
  <Container>
    <Grid>
      <GridItem tabletP={14} tabletPStart={2} tabletL={14} tabletLStart={2}>
        <StatsMain>
          {React.Children.toArray(
            items.map(item => (
              <Stat
                prefix={item.prefix}
                value={item.number}
                suffix={item.suffix}
                label={item.label}
                starIcon={item.starIcon}
              />
            ))
          )}
        </StatsMain>
      </GridItem>
    </Grid>
  </Container>
)

Stats.propTypes = {
  items: PropTypes.array,
}

export default Stats
