import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'
import gsap from 'gsap'
import PropTypes from 'prop-types'
import { useSwipeable } from 'react-swipeable'
import { useInView } from 'react-intersection-observer'
import {
  Testimonial,
  TestimonialAuthor,
  TestimonialLogo,
  TestimonialQuote,
  TestimonialRating,
  TestimonialsDots,
  TestimonialsFooter,
  TestimonialsMain,
  TestimonialsProgress,
  TestimonialsSlider,
} from './index.style'
import Container from '../Container'
import RoundedBackground from '../RoundedBackground'
import Spacer from '../Spacer'
import LinkButton from '../LinkButton'
import { Heading4, TextBodySmall } from '../TextStyles'
import AnimateSplitText from '../animation/AnimateSplitText'
import IconStarRating from '../svgs/IconStarRating'
import CircleProgress from '../CircleProgress'
import Grid from '../Grid'
import GridItem from '../GridItem'
import { AnimateSlideInMask } from '../animation/AnimateSlideIn/index.style'
import { useStore } from '../../Store'
import { SliderDotsButton, SliderDotsItem } from '../Slider/index.style'

const Testimonials = ({
  items,
  ctaText,
  ctaTarget,
  roundedBackground = false,
}) => {
  const [store] = useStore()
  const [sliderRef, sliderInView] = useInView({ triggerOnce: false })
  const [activeIndex, setActiveIndex] = useState(0)
  const [activeTextIndex, setActiveTextIndex] = useState(null)
  const [activeDotIndex, setActiveDotIndex] = useState(0)
  const [ready, setReady] = useState(false)
  const tl = useRef()
  const tlExit = useRef()
  const $slider = useRef()
  const $slides = useRef([])
  const $logos = useRef([])
  const $stars = useRef([])
  const $images = useRef([])
  const $quotes = useRef([])
  const $authors = useRef([])
  const autoplaySpeed = 8000
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
      updateSlide(nextIndex)
    },
    onSwipedRight: () => {
      const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
      updateSlide(nextIndex)
    },
  })

  gsap.defaults({
    duration: 0.3,
    ease: 'power2.inOut',
  })

  const exitAnimation = () => {
    const tl = gsap.timeline()

    if ($images.current.length) {
      tl.to(
        $images.current,
        {
          yPercent: 100,
          duration: 0.15,
        },
        '<'
      )
    }

    tl.add(gsap.delayedCall(0, () => setActiveTextIndex(null)))

    return tl
  }

  const updateSlide = slideIndex => {
    if (slideIndex === activeIndex || !sliderInView) return

    tl.current.pause()
    setActiveDotIndex(slideIndex)

    if (tlExit.current) tlExit.current.kill()

    tlExit.current = gsap.timeline({
      onComplete: () => setActiveIndex(slideIndex),
    })

    tlExit.current.add(exitAnimation())
  }

  // Delay starting the carousel animation until the font is loaded and splitText has run
  useEffect(() => {
    if (store.fontIsLoaded) {
      gsap.delayedCall(0.2, () => {
        setReady(true)
        gsap.set($slider.current, { opacity: 1 })
      })
    }
  }, [store.fontIsLoaded])

  // Update the active slide element refs
  useLayoutEffect(() => {
    if (!ready) return

    $images.current = []
    const $logo = $logos.current[activeIndex]
    const $star = $stars.current[activeIndex]

    if ($logo) $images.current.push($logo)
    if ($star) $images.current.push($star)
  }, [activeIndex, ready])

  // Setup the timeline and resize event
  useEffect(() => {
    if (!store.fontIsLoaded) return

    const setSliderHeight = () => {
      const tallestSlide = $slides.current.reduce((prevSlide, currentSlide) => {
        return prevSlide.offsetHeight > currentSlide.offsetHeight
          ? prevSlide
          : currentSlide
      })

      tallestSlide.style.position = 'relative'
    }

    tl.current = gsap.timeline({
      paused: true,
    })

    setSliderHeight()

    return () => {
      if (tl.current) tl.current.kill()
      if (tlExit.current) tlExit.current.kill()
    }
  }, [items.length, store.fontIsLoaded])

  // Pause autoplay when the slider is outside the viewport
  useEffect(() => {
    if (!ready) return

    if (sliderInView) {
      tl.current.play()
    } else {
      tl.current.pause()
    }
  }, [sliderInView, ready])

  // setup autoplay animation
  useEffect(() => {
    if (!ready) return

    if (tl.current) tl.current.clear()

    if ($images.current.length) {
      tl.current.to($images.current, {
        yPercent: -100,
      })
    }

    tl.current.add(gsap.delayedCall(0.1, () => setActiveTextIndex(activeIndex)))

    if (items.length > 1)
      tl.current.add(exitAnimation(), `>${autoplaySpeed / 1000 - 0.5}`)

    tl.current.eventCallback('onComplete', () => {
      const nextActiveIndex =
        activeIndex === items.length - 1 ? 0 : activeIndex + 1

      setActiveIndex(nextActiveIndex)
      setActiveDotIndex(nextActiveIndex)
    })

    tl.current.play()
  }, [activeIndex, ready, items.length])

  return (
    <TestimonialsMain {...swipeHandlers}>
      {roundedBackground && <RoundedBackground />}

      <Container>
        <Grid>
          <GridItem tabletP={11} tabletPStart={2} desk={11} deskStart={2}>
            <Spacer size={[80, 200]} />

            <div ref={sliderRef}>
              <TestimonialsSlider ref={$slider}>
                {React.Children.toArray(
                  items.map((item, itemIndex) => (
                    <Testimonial
                      ref={ref => ($slides.current[itemIndex] = ref)}
                      active={activeIndex === itemIndex}
                      aria-hidden={activeIndex !== itemIndex}
                    >
                      {item.logo && (
                        <>
                          <AnimateSlideInMask>
                            <TestimonialLogo
                              ref={ref => ($logos.current[itemIndex] = ref)}
                              src={item.logo.file.url}
                              alt={item.logo.description}
                              maxWidth={item.logo.file.details.image.width}
                            />
                          </AnimateSlideInMask>

                          <Spacer size={[30, 70]} />
                        </>
                      )}

                      {item.rating && (
                        <>
                          <AnimateSlideInMask>
                            <TestimonialRating
                              ref={ref => ($stars.current[itemIndex] = ref)}
                            >
                              <IconStarRating rating={item.rating} />
                            </TestimonialRating>
                          </AnimateSlideInMask>

                          <Spacer size={[24, 48]} />
                        </>
                      )}

                      <TestimonialQuote
                        ref={ref => ($quotes.current[itemIndex] = ref)}
                        className="testi"
                      >
                        <Heading4>
                          <AnimateSplitText
                            animate={activeTextIndex === itemIndex}
                          >
                            {item.quote.quote}
                          </AnimateSplitText>
                        </Heading4>
                      </TestimonialQuote>

                      <Spacer size={[25, 50]} />

                      <TestimonialAuthor
                        ref={ref => ($authors.current[itemIndex] = ref)}
                      >
                        <TextBodySmall>
                          <AnimateSplitText
                            animate={activeTextIndex === itemIndex}
                          >
                            {item.author}
                          </AnimateSplitText>
                        </TextBodySmall>
                      </TestimonialAuthor>
                    </Testimonial>
                  ))
                )}
              </TestimonialsSlider>
            </div>
          </GridItem>

          <GridItem tabletP={14} tabletPStart={2}>
            {items.length > 1 && (
              <TestimonialsFooter>
                <TestimonialsDots>
                  {React.Children.toArray(
                    items.map((item, itemIndex) => (
                      <SliderDotsItem>
                        <SliderDotsButton
                          onClick={() => updateSlide(itemIndex)}
                          active={itemIndex === activeDotIndex}
                        >
                          {itemIndex}
                        </SliderDotsButton>
                      </SliderDotsItem>
                    ))
                  )}
                </TestimonialsDots>

                <TestimonialsProgress>
                  <CircleProgress
                    animate={sliderInView && ready}
                    animationDuration={autoplaySpeed}
                    update={activeIndex}
                  />
                </TestimonialsProgress>
              </TestimonialsFooter>
            )}

            <Spacer size={[24, 48]} />

            <LinkButton text={ctaText} target={ctaTarget} mobilefill />

            <Spacer size={[90, 150]} />
          </GridItem>
        </Grid>
      </Container>
    </TestimonialsMain>
  )
}

Testimonials.propTypes = {
  items: PropTypes.array,
  ctaText: PropTypes.string,
  ctaTarget: PropTypes.object,
  roundedBackground: PropTypes.bool,
}

export default Testimonials
