import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'

export const SliderDots = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
`

export const SliderDotsItem = styled.li``

export const SliderDotsButton = styled.button`
  position: relative;
  width: 3.4rem;
  height: 3.4rem;
  overflow: hidden;
  white-space: nowrap;
  text-indent: 100%;
  border: none;
  background: transparent;

  ::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    pointer-events: none;
    border-radius: 50%;
    background-color: ${colors.dark};
    opacity: ${props => (props.active ? 1 : 0.1)};
    transition: opacity 0.4s ${easings.inOut.default};
  }

  :hover {
    ::before {
      opacity: ${props => (!props.active ? 0.3 : 1)};
    }
  }
`
