import React, { useRef, useEffect } from 'react'
import gsap from 'gsap'
import { useInView } from 'react-intersection-observer'
import { TextPlugin } from 'gsap/TextPlugin'
import PropTypes from 'prop-types'
import {
  StatMain,
  StatTotal,
  StatValue,
  StatSuffix,
  StatText,
  StatSuffixIcon,
} from './index.style'
import { Label, TextBody } from '../../TextStyles'
import AnimateSlideIn from '../../animation/AnimateSlideIn'
import { useStore } from '../../../Store'
import IconStar from '../../svgs/IconStar'

gsap.registerPlugin(TextPlugin)

const Stat = ({ value, prefix, suffix, label, starIcon }) => {
  const [ref, inView] = useInView({ triggerOnce: true })
  const $value = useRef()
  const [store] = useStore()

  useEffect(() => {
    if (store.fontIsLoaded && !store.showPageMask && inView) {
      gsap.from($value.current, {
        textContent: 0,
        duration: 2,
        snap: { textContent: value > 100 ? 10 : value > 10 ? 1 : 0.1 },
        ease: 'power2.inOut',
        onComplete: () => {
          gsap.set($value.current, {
            clearProps: 'width',
          })
        },
      })
    }
  }, [store.fontIsLoaded, store.showPageMask, inView, value])

  return (
    <StatMain
      ref={ref}
      aria-label={`${prefix ? prefix : ''}${value}${
        suffix ? suffix : ''
      } ${label}`}
    >
      <StatTotal aria-hidden>
        {prefix && (
          <StatSuffix>
            <Label as="span">
              <AnimateSlideIn>{prefix}</AnimateSlideIn>
            </Label>
          </StatSuffix>
        )}

        <StatValue as="span">
          <AnimateSlideIn>
            <Label ref={$value} as="span">
              {value}
            </Label>
          </AnimateSlideIn>
        </StatValue>

        {starIcon === 'True' && (
          <StatSuffixIcon>
            <AnimateSlideIn>
              <IconStar />
            </AnimateSlideIn>
          </StatSuffixIcon>
        )}

        {suffix && (
          <StatSuffix>
            <Label as="span">
              <AnimateSlideIn>{suffix}</AnimateSlideIn>
            </Label>
          </StatSuffix>
        )}
      </StatTotal>

      <StatText aria-hidden>
        <TextBody as="span">
          <AnimateSlideIn>{label}</AnimateSlideIn>
        </TextBody>
      </StatText>
    </StatMain>
  )
}

Stat.propTypes = {
  value: PropTypes.string,
  suffix: PropTypes.string,
  label: PropTypes.string,
  starIcon: PropTypes.string,
}

export default Stat
