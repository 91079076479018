import styled from 'styled-components'
import { mq } from '../../../styles/vars/media-queries.style'
import { AccordionMain } from '../../Accordion/index.style'

export const BenefitsMain = styled.div`
  position: relative;
  overflow: hidden;
`

export const BenefitsWrapper = styled.div`
  position: relative;
`

export const BenefitsDotA = styled.div`
  position: absolute;
  top: 9.9rem;
  left: 0;
  transform: translate(-50%, 0) rotate(180deg);
  mix-blend-mode: multiply;

  ${mq.tabletLMax} {
    display: none;
  }
`

export const BenefitsDotB = styled.div`
  position: absolute;
  right: 0;
  bottom: 24.4rem;
  transform: translate(50%, 0);
  mix-blend-mode: multiply;

  ${mq.tabletLMax} {
    display: none;
  }
`

export const BenefitsItems = styled.div`
  ${AccordionMain}:first-child {
    ${mq.tabletL} {
      padding-top: 0;
    }
  }
`
