import { transparentize } from 'polished'
import styled from 'styled-components'
import { clamp } from '../../../styles/utils/conversion.style'
import { colors } from '../../../styles/vars/colors.style'

export const StatsMain = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${clamp(
    'padding-bottom',
    20,
    60
  )}// border-bottom: 0.1rem solid ${transparentize(0.8, colors.dark)};
`
