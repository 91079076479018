import styled from 'styled-components'
import { clamp } from '../../../styles/utils/conversion.style'
import { mq } from '../../../styles/vars/media-queries.style'
import { Label } from '../../../styles/vars/textStyles.style'

export const StatMain = styled.p`
  width: 50%;
  margin-bottom: 4rem;
  text-align: left;

  ${mq.mobileL} {
    width: 33.333%;
  }

  ${mq.tabletP} {
    text-align: center;
  }

  ${mq.tabletL} {
    width: 16.6666%;
  }
`

export const StatTotal = styled.span`
  display: flex;

  ${mq.tabletP} {
    justify-content: center;
  }
`

export const StatValue = styled.span`
  display: block;

  ${Label} {
    display: block;
  }
`

export const StatSuffix = styled.span``

export const StatSuffixIcon = styled.span`
  display: flex;
  align-items: center;
  ${clamp('width', 14, 20)}
  margin-left: 0.5rem;

  span {
    width: 100%;
  }
`

export const StatText = styled.span`
  display: block;
  margin-top: 0.5rem;
`
