import React from 'react'
import PropTypes from 'prop-types'
import { TeamImage, TeamImageMobile, TeamMain } from './index.style'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import { Heading4 } from '../../TextStyles'
import AnimateSplitText from '../../animation/AnimateSplitText'
import AnimateFadeIn from '../../animation/AnimateFadeIn'
import Spacer from '../../Spacer'
import RichText from '../../RichText'
import { GatsbyImage } from 'gatsby-plugin-image'
import LinkButton from '../../LinkButton'

const Team = ({ title, text, image, imageMobile, ctaText, ctaTarget }) => (
  <Container>
    <Spacer size={[90, 250]} />

    <Grid>
      <GridItem tabletP={7} desk={6} deskStart={2}>
        <TeamImageMobile maxWidth={imageMobile.file.details.image.width}>
          <AnimateFadeIn>
            <GatsbyImage
              image={imageMobile.gatsbyImageData}
              alt={imageMobile.description}
            />
          </AnimateFadeIn>
        </TeamImageMobile>

        <TeamImage maxWidth={image.file.details.image.width}>
          <AnimateFadeIn>
            <GatsbyImage
              image={image.gatsbyImageData}
              alt={image.description}
            />
          </AnimateFadeIn>
        </TeamImage>
      </GridItem>

      <GridItem tabletP={8} tabletPStart={9} desk={5} deskStart={10}>
        <TeamMain>
          <Heading4 as="h3">
            <AnimateSplitText>{title}</AnimateSplitText>
          </Heading4>

          <Spacer size={[26, 35]} />

          <RichText content={text} paragraphSize="large" />

          <Spacer size={[26, 35]} />

          <LinkButton text={ctaText} target={ctaTarget} variant="secondary" />
        </TeamMain>
      </GridItem>
    </Grid>

    <Spacer size={[90, 250]} />
  </Container>
)

Team.propTypes = {
  title: PropTypes.string,
  text: PropTypes.object,
  image: PropTypes.object,
  imageMobile: PropTypes.object,
  ctaText: PropTypes.string,
  ctaTarget: PropTypes.object,
}

export default Team
