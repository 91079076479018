import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import Hero from '../components/Home/Hero'
import Stats from '../components/Home/Stats'
import Features from '../components/Home/Features'
// import Returns from '../components/Home/Returns'
import Testimonials from '../components/Testimonials'
import Mining from '../components/Mining'
import Benefits from '../components/Home/Benefits'
import Team from '../components/Home/Team'
import FeaturedIn from '../components/FeaturedIn'

const HomePage = ({ data }) => {
  const {
    seoTitle,
    seoDescriptions,
    heroTitle,
    heroText,
    heroCtaText,
    heroCtaTarget,
    heroBadge,
    heroImage,
    stats,
    // returnsTitle,
    // returnsText,
    // returnsCtaText,
    // returnsCtaTarget,
    // returns,
    features,
    featuresImage,
    testimonials,
    testimonialsCtaText,
    testimonialsCtaTarget,
    miningTitle,
    miningText,
    miningCtaText,
    miningCtaTarget,
    miningImage,
    benefitsTitle,
    benefitsText,
    benefits,
    teamTitle,
    teamText,
    teamImage,
    teamImageMobile,
    teamCtaText,
    teamCtaTarget,
    featuredInText,
    featuredInBrands,
  } = data.contentfulHome

  // const formattedReturns = returns.map(returnItem => {
  //   returnItem.direction = returnItem.roiPercentage?.substring(0, 1)
  //   returnItem.roiValue = returnItem.roiPercentage?.substring(1)

  //   return returnItem
  // })

  return (
    <>
      <Seo title={seoTitle} description={seoDescriptions?.seoDescriptions} />

      <Hero
        title={heroTitle}
        text={heroText}
        ctaText={heroCtaText}
        ctaTarget={heroCtaTarget}
        badge={heroBadge}
        image={heroImage}
        qr={true}
      />

      <Stats items={stats} />

      {/* <Returns
        title={returnsTitle}
        text={returnsText}
        ctaText={returnsCtaText}
        ctaTarget={returnsCtaTarget}
        items={formattedReturns}
      /> */}

      <Features items={features} image={featuresImage} />

      <Testimonials
        items={testimonials}
        ctaText={testimonialsCtaText}
        ctaTarget={testimonialsCtaTarget}
      />

      <Mining
        title={miningTitle}
        text={miningText}
        ctaText={miningCtaText}
        ctaTarget={miningCtaTarget}
        image={miningImage}
      />

      <Benefits title={benefitsTitle} text={benefitsText} items={benefits} />

      <Team
        title={teamTitle}
        text={teamText}
        image={teamImage}
        imageMobile={teamImageMobile}
        ctaText={teamCtaText}
        ctaTarget={teamCtaTarget}
      />

      <FeaturedIn title={featuredInText} brands={featuredInBrands} />
    </>
  )
}

export default HomePage

export const pageQuery = graphql`
  query Home {
    contentfulHome {
      seoTitle
      seoDescriptions {
        seoDescriptions
      }
      seoImage {
        gatsbyImageData
      }
      heroTitle
      heroText {
        raw
      }
      heroCtaText
      heroCtaTarget {
        ... on Node {
          ...ContentfulLink
        }
      }
      heroBadge {
        file {
          url
        }
        description
      }
      heroImage {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
        description
      }
      stats {
        number
        prefix
        suffix
        label
        starIcon
      }
      returnsTitle
      returnsText {
        raw
      }
      returnsCtaText
      returnsCtaTarget {
        ... on Node {
          ...ContentfulLink
        }
      }
      returns {
        contentfulid
        title
        roiPercentage
      }
      features {
        title
        text {
          text
        }
      }
      featuresImage {
        gatsbyImageData(quality: 100)
        description
      }
      testimonials {
        rating
        quote {
          quote
        }
        author
      }
      testimonialsCtaText
      testimonialsCtaTarget {
        ... on Node {
          ...ContentfulLink
        }
      }
      miningTitle
      miningText {
        raw
      }
      miningCtaText
      miningCtaTarget {
        ... on Node {
          ...ContentfulLink
        }
      }
      miningImage {
        gatsbyImageData(quality: 100)
        description
      }
      benefitsTitle
      benefitsText {
        raw
      }
      benefits {
        ... on Node {
          ...ContentfulListItems
        }
      }
      teamTitle
      teamText {
        raw
      }
      teamImage {
        gatsbyImageData(quality: 100)
        description
        file {
          details {
            image {
              width
            }
          }
        }
      }
      teamImageMobile {
        gatsbyImageData(quality: 100)
        description
        file {
          details {
            image {
              width
            }
          }
        }
      }
      teamCtaText
      teamCtaTarget {
        ... on Node {
          ...ContentfulLink
        }
      }
      featuredInText
      featuredInBrands {
        file {
          url
          details {
            image {
              width
            }
          }
        }
        description
      }
    }
  }
`
