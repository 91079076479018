import styled from 'styled-components'
import { mq } from '../../../styles/vars/media-queries.style'

export const TeamMain = styled.div`
  ${mq.tabletPMax} {
    margin-top: 5.2rem;
  }

  ${mq.tabletP} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
`

export const TeamImageMobile = styled.div`
  max-width: ${props => `${props.maxWidth / 10}rem`};
  margin-left: auto;
  margin-right: auto;

  ${mq.tabletP} {
    display: none;
  }
`

export const TeamImage = styled.div`
  max-width: ${props => `${props.maxWidth / 10}rem`};
  margin-left: auto;
  margin-right: auto;

  ${mq.tabletPMax} {
    display: none;
  }
`
