import styled from 'styled-components'
import { clamp } from '../../../styles/utils/conversion.style'
import { breakpoints } from '../../../styles/vars/breakpoints.style'
import { colors } from '../../../styles/vars/colors.style'
import { mq } from '../../../styles/vars/media-queries.style'
import { GridMain } from '../../Grid/index.style'
import { SliderDots } from '../../Slider/index.style'

export const FeaturesMain = styled.div`
  position: relative;
`

export const FeaturesContent = styled.div`
  position: relative;

  ${GridMain} {
    grid-gap: 0;
  }
`

export const FeaturesBackground = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  pointer-events: none;
  background-color: ${colors.grey};
  border-radius: 1.5rem;
`

export const FeaturesImage = styled.div`
  position: relative;
  ${clamp('padding-top', 48, 156)}
  ${clamp('padding-bottom', 48, 156)}
  ${clamp('padding-right', 28, 80)}
  ${clamp('padding-left', 28, 80)}
`

export const FeaturesImageDots = styled.div`
  position: absolute;
  ${clamp('top', 28, 72)}
  ${clamp('bottom', 28, 72)}
  ${clamp('right', 28, 80)}
  ${clamp('left', 28, 80)}
`

export const FeaturesDivider = styled.div`
  position: absolute;
  bottom: 0;
  background-color: ${colors.greyMid3};

  ${mq.tabletPMax} {
    right: 0;
    left: 0;
    height: 0.1rem;
  }

  ${mq.tabletP} {
    top: 0;
    left: 100%;
    width: 0.1rem;
  }
`

export const FeaturesDot = styled.div`
  position: absolute;
  top: 10%;
  left: 0;
  transform: translate(-50%, -50%);
  mix-blend-mode: multiply;
`

export const FeaturesSlider = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 4.8rem 2.8rem;
  opacity: 0;

  ${mq.desk} {
    padding: 4.8rem 0 4.8rem 2.8rem;
  }
`

export const FeaturesSliderInner = styled.div`
  position: relative;
  width: 100%;
`

export const Feature = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  transform: translateY(-50%);
  pointer-events: ${props => (props.active ? undefined : 'none')};
`

export const FeaturesDots = styled(SliderDots)`
  position: absolute;
  justify-content: center;
  z-index: 1;

  ${mq.deskMax} {
    right: 0;
    bottom: -0.9rem;
    left: 0;
    transform: translateY(100%);
  }

  ${mq.desk} {
    top: 50%;
    ${clamp('right', 32, 64, breakpoints.desk)}
    transform: translateY(-50%);
    flex-direction: column;
  }
`
