import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { SliderDots } from '../Slider/index.style'

export const TestimonialsMain = styled.div`
  position: relative;
`

export const TestimonialsSlider = styled.div`
  position: relative;
  opacity: 0;
`

export const Testimonial = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  pointer-events: ${props => (props.active ? undefined : 'none')};

  .split__text {
    transition-delay: 0s;
    transition-duration: 0.3s;
  }
`

export const TestimonialLogo = styled.img`
  transform: translateY(100%);
`

export const TestimonialRating = styled.div`
  transform: translateY(100%);
  ${clamp('width', 141, 340)}
`

export const TestimonialQuote = styled.div``

export const TestimonialAuthor = styled.div``

export const TestimonialsFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TestimonialsDots = styled(SliderDots)`
  margin-left: -1.3rem;
`

export const TestimonialsProgress = styled.div``
