import React from 'react'
import { colors } from '../../../styles/vars/colors.style'

const IconStarRating = ({
  width = 340,
  height = 60,
  fill = colors.green,
  responsive = true,
  rating = 5,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 340 60"
      aria-label={`${rating} star rating`}
      focusable="false"
      {...svgAttributes}
    >
      <path
        fill={fill}
        d="m30 0 9.699 16.651 18.833 4.079-12.84 14.369 1.942 19.172L30 46.5l-17.634 7.77 1.942-19.17-12.84-14.37 18.834-4.078L30 0Z"
        opacity={rating >= 1 ? 1 : 0}
      />
      <path
        fill={fill}
        d="m100 0 9.698 16.651 18.834 4.079-12.84 14.369 1.942 19.172L100 46.5l-17.634 7.77 1.942-19.17-12.84-14.37 18.834-4.078L100 0Z"
        opacity={rating >= 2 ? 1 : 0}
      />
      <path
        fill={fill}
        d="m170 0 9.698 16.651 18.834 4.079-12.84 14.369 1.942 19.172L170 46.5l-17.634 7.77 1.942-19.171-12.84-14.37 18.834-4.078L170 0Z"
        opacity={rating >= 3 ? 1 : 0}
      />
      <path
        fill={fill}
        d="m240 0 9.698 16.651 18.834 4.079-12.84 14.369 1.942 19.172L240 46.5l-17.634 7.77 1.942-19.171-12.84-14.37 18.834-4.078L240 0Z"
        opacity={rating >= 4 ? 1 : 0}
      />
      <path
        fill={fill}
        d="m310 0 9.698 16.651 18.834 4.079-12.84 14.369 1.942 19.172L310 46.5l-17.634 7.77 1.942-19.171-12.84-14.37 18.834-4.078L310 0Z"
        opacity={rating >= 5 ? 1 : 0}
      />
    </svg>
  )
}

export default IconStarRating
