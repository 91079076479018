import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Spacer from '../../Spacer'
import { Heading1 } from '../../TextStyles'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import RichText from '../../RichText'
import AnimateSplitText from '../../animation/AnimateSplitText'
import AnimateFadeIn from '../../animation/AnimateFadeIn'
import AnimateSlideIn from '../../animation/AnimateSlideIn'
import Dot from '../../svgs/Dot'
import DotHalf from '../../svgs/DotHalf'
import {
  HeroImage,
  HeroContent,
  HeroMain,
  HeroText,
  HeroLink,
  HeroQR,
  HeroQRInner,
  HeroBadge,
  HeroImageDotA,
  HeroImageDotB,
  HeroImageInner,
  HeroWrapper,
  HeroDot,
  HeroDots,
} from './index.style'
import { GatsbyImage } from 'gatsby-plugin-image'
import { animation } from '../../../styles/vars/animation.style'
import Dots from '../../Dots'
import Button from '../../Button'
import LinkButton from '../../LinkButton'

const Hero = ({
  title,
  text,
  ctaText,
  ctaTarget,
  contactCta,
  badge,
  image,
  qr,
}) => {
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const [clickURL, setClickURL] = useState(false)

  const scrollToContact = () => {
    const target = document.querySelector('#contact')

    if (!target) return

    window.scroll({
      top: target.getBoundingClientRect().top + window.pageYOffset,
      left: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    if (window.innerWidth < 1240 || !qr) return

    const script = document.createElement('script')
    const src =
      'https://onelinksmartscript.appsflyer.com/onelink-smart-script-latest.js'
    script.src = src
    script.onload = () => setScriptLoaded(true)
    document.body.appendChild(script)
  }, [qr])

  useEffect(() => {
    if (!scriptLoaded) return

    var result = window.AF_SMART_SCRIPT.generateOneLinkURL({
      oneLinkURL: 'https://emberfund.onelink.me/ljTI/emberfundhome',
      afParameters: {
        mediaSource: {
          keys: [''],
          defaultValue: [''],
        },
        channel: {
          keys: [''],
          defaultValue: ['website'],
        },
        adSet: {
          keys: [''],
          defaultValue: ['homepage'],
        },
      },
    })

    result && setClickURL(result.clickURL)
  }, [scriptLoaded])

  useEffect(() => {
    if (!clickURL) return
    window.AF_SMART_SCRIPT.displayQrCode('onelink_qr_code')
  }, [clickURL])

  return (
    <HeroMain>
      <HeroWrapper>
        <HeroDot>
          <AnimateSlideIn>
            <DotHalf />
          </AnimateSlideIn>
        </HeroDot>
        <HeroDot>
          <AnimateSlideIn delay={0.1}>
            <Dot />
          </AnimateSlideIn>
        </HeroDot>
        <HeroDot>
          <AnimateSlideIn delay={0.2}>
            <DotHalf />
          </AnimateSlideIn>
        </HeroDot>

        <Container>
          <HeroDots>
            <Dots lerpOpacityScale={0.5} />
          </HeroDots>

          <Grid>
            <GridItem
              tabletP={12}
              tabletPStart={2}
              tabletL={7}
              tabletLStart={2}
            >
              <HeroContent>
                <Heading1 as={`h1`}>
                  <AnimateSplitText>{title}</AnimateSplitText>
                </Heading1>

                <Spacer size={[20, 35]} />

                <HeroText>
                  <RichText
                    content={text}
                    paragraphSize="large"
                    maxWidth={21.46}
                  />
                </HeroText>

                <Spacer size={[40, 60]} />

                <HeroLink>
                  {ctaTarget && (
                    <AnimateFadeIn>
                      <LinkButton
                        text={ctaText}
                        target={ctaTarget}
                        mobilefill
                      />
                    </AnimateFadeIn>
                  )}

                  {badge && (
                    <AnimateFadeIn>
                      <HeroBadge src={badge.file.url} alt={badge.description} />
                    </AnimateFadeIn>
                  )}

                  {qr && (
                    <HeroQR visible={clickURL}>
                      <AnimateFadeIn>
                        <HeroQRInner>
                          <div id="onelink_qr_code" />
                        </HeroQRInner>
                      </AnimateFadeIn>
                    </HeroQR>
                  )}

                  {contactCta && (
                    <AnimateFadeIn>
                      <Button
                        type="button"
                        onClick={scrollToContact}
                        mobilefill
                      >
                        {ctaText}
                      </Button>
                    </AnimateFadeIn>
                  )}
                </HeroLink>
              </HeroContent>
            </GridItem>
          </Grid>

          <HeroImage>
            <HeroImageInner>
              <AnimateFadeIn>
                <GatsbyImage
                  image={image.gatsbyImageData}
                  alt={image.description}
                />
              </AnimateFadeIn>
            </HeroImageInner>

            <HeroImageDotA>
              <AnimateSlideIn>
                <Dot delay={animation.maskShowDuration} />
              </AnimateSlideIn>
            </HeroImageDotA>

            <HeroImageDotB>
              <AnimateSlideIn>
                <DotHalf delay={animation.maskShowDuration} />
              </AnimateSlideIn>
            </HeroImageDotB>
          </HeroImage>
        </Container>
      </HeroWrapper>
    </HeroMain>
  )
}

Hero.propTypes = {
  title: PropTypes.string,
  text: PropTypes.object,
  ctaText: PropTypes.string,
  ctaTarget: PropTypes.object,
  contactCta: PropTypes.bool,
  image: PropTypes.object,
  qr: PropTypes.bool,
}

Hero.defaultProps = {
  qr: false,
}

export default Hero
