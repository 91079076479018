import React, { useEffect, useRef } from 'react'
import gsap from 'gsap'
import PropTypes from 'prop-types'
import { colors } from '../../styles/vars/colors.style'
import { CircleProgressStroke } from './index.style'

const CircleProgress = ({
  animate = false,
  diameter = 33,
  strokeWidth = 2,
  percentage = 0,
  update = 0,
  animationDuration = 1000,
}) => {
  const tl = useRef()
  const $progressBar = useRef()
  const radius = useRef((diameter - strokeWidth) / 2)
  const dashArray = useRef(radius.current * Math.PI * 2)
  const getDashOffset = percentage =>
    dashArray.current - (dashArray.current * percentage) / 100
  const dashOffset = getDashOffset(percentage)

  useEffect(() => {
    tl.current = gsap.timeline({ paused: true })

    tl.current.fromTo(
      $progressBar.current,
      { strokeDashoffset: getDashOffset(0) },
      {
        strokeDashoffset: getDashOffset(100),
        duration: animationDuration / 1000,
        ease: 'none',
      }
    )

    return () => {
      tl.current.kill()
    }
  }, [animationDuration])

  useEffect(() => {
    if (!animate) {
      tl.current.pause()
    } else {
      if (tl.current.paused()) {
        tl.current.play()
      } else {
        tl.current.restart()
      }
    }
  }, [animate, update, animationDuration])

  return (
    <svg
      width={diameter}
      height={diameter}
      viewBox={`0 0 ${diameter} ${diameter}`}
    >
      <circle
        fill="none"
        cx={diameter / 2}
        cy={diameter / 2}
        r={radius.current}
        stroke={colors.dark}
        strokeWidth={`${strokeWidth}px`}
        opacity={0.1}
      />

      <CircleProgressStroke
        ref={$progressBar}
        fill="none"
        cx={diameter / 2}
        cy={diameter / 2}
        r={radius.current}
        strokeWidth={`${strokeWidth}px`}
        transform={`rotate(-90 ${diameter / 2} ${diameter / 2})`}
        stroke={colors.green}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray={dashArray.current}
        strokeDashoffset={dashOffset}
      />
    </svg>
  )
}

CircleProgress.propTypes = {
  animate: PropTypes.bool,
  diameter: PropTypes.number,
  strokeWidth: PropTypes.number,
  percentage: PropTypes.number,
  update: PropTypes.number,
  animationDuration: PropTypes.number,
}

export default CircleProgress
