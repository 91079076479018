import styled from 'styled-components'
import { clamp } from '../../../styles/utils/conversion.style'
import { breakpoints } from '../../../styles/vars/breakpoints.style'
import { mq } from '../../../styles/vars/media-queries.style'

import {
  ContainerMain,
  matchContainerPadding,
} from '../../Container/index.style'

export const HeroMain = styled.div`
  ${clamp('padding-bottom', 90, 210, breakpoints.mobile, breakpoints.deskM)}
  position: relative;

  ${mq.deskMMax} {
    ${clamp('padding-top', 130, 210, breakpoints.mobile, breakpoints.deskM)}
  }

  ${mq.deskM} {
    ${clamp('padding-top', 210, 230, breakpoints.deskM, breakpoints.deskL)}
  }
`

export const HeroWrapper = styled.div`
  position: relative;

  ${ContainerMain} {
    position: relative;
  }
`

export const HeroDot = styled.div`
  position: absolute;
  z-index: 1;

  :nth-child(1) {
    top: 11.4rem;
    right: 6rem;

    ${mq.tabletL} {
      top: -11rem;
      right: 47%;
      transform: translateY(-100%);
    }
  }

  :nth-child(2) {
    display: none;

    ${mq.tabletL} {
      top: 0;
      right: 8%;
      transform: translateY(-100%);
      display: block;
    }
  }

  :nth-child(3) {
    right: 0;
    transform-origin: 100% 100%;
    transform: rotate(-90deg);

    ${mq.tabletL} {
      bottom: 10%;
    }
  }
`

export const HeroContent = styled.div`
  position: relative;
  z-index: 1;
`

export const HeroImage = styled.div`
  position: relative;

  ${mq.tabletLMax} {
    margin-top: 6rem;
    ${matchContainerPadding('margin-left', true)}
    ${matchContainerPadding('margin-right', true)}
  }

  ${mq.tabletL} {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    width: 53%;
    max-width: 110.9rem;
  }
`

export const HeroDots = styled.div`
  position: absolute;
  right: 2.4rem;
  left: 53%;
  top: -20%;
  bottom: 0;
`

export const HeroImageInner = styled.div`
  ${mq.tabletLMax} {
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
  }
`

export const HeroImageDotA = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-50%);

  ${mq.tabletL} {
    display: none;
  }
`

export const HeroImageDotB = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateX(-50%) rotate(90deg);

  ${mq.tabletL} {
    display: none;
  }
`

export const HeroText = styled.div``

export const HeroLink = styled.div`
  ${mq.desk} {
    align-items: center;
    display: flex;
  }
`

export const HeroQR = styled.div`
  display: none;
  margin-left: 4rem;

  ${mq.desk} {
    display: ${props => (props.visible ? `block` : `none`)};
  }
`

export const HeroQRInner = styled.div`
  canvas {
    height: auto;
    ${clamp('width', 122, 122)};
  }
`

export const HeroBadge = styled.img`
  ${clamp('height', 42, 52)};
  margin-top: 2rem;

  @media (max-width: 639px) {
    margin-left: auto;
    margin-right: auto;
  }

  ${mq.desk} {
    display: none;
    margin-top: 0;
    margin-left: 4rem;
  }
`
