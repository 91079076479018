import React from 'react'
import PropTypes from 'prop-types'
import {
  BenefitsDotA,
  BenefitsDotB,
  BenefitsItems,
  BenefitsMain,
  BenefitsWrapper,
} from './index.style'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import Spacer from '../../Spacer'
import RoundedBackground from '../../RoundedBackground'
import { Heading2, Heading4 } from '../../TextStyles'
import AnimateSplitText from '../../animation/AnimateSplitText'
import DotHalf from '../../svgs/DotHalf'
import AnimateSlideIn from '../../animation/AnimateSlideIn'
import Dot from '../../svgs/Dot'
import RichText from '../../RichText'
import Accordion from '../../Accordion'

const Benefits = ({ title, text, items }) => (
  <BenefitsMain>
    <RoundedBackground>
      <BenefitsDotA>
        <AnimateSlideIn>
          <DotHalf />
        </AnimateSlideIn>
      </BenefitsDotA>
      <BenefitsDotB>
        <AnimateSlideIn delay={0.1}>
          <Dot />
        </AnimateSlideIn>
      </BenefitsDotB>
    </RoundedBackground>

    <Container>
      <BenefitsWrapper>
        <Spacer size={[90, 250]} />

        <Grid>
          <GridItem tabletP={12} tabletPStart={3} tabletL={5} tabletLStart={2}>
            <Heading4 as="h3" maxWidth={11.09}>
              <AnimateSplitText>{title}</AnimateSplitText>
            </Heading4>

            {text && (
              <>
                <Spacer size={[20, 35]} />
                <RichText
                  content={text}
                  paragraphSize="large"
                  maxWidth={19.6}
                />
              </>
            )}
          </GridItem>

          <GridItem tabletP={12} tabletPStart={3} tabletL={7} tabletLStart={9}>
            <BenefitsItems>
              {React.Children.toArray(
                items.map((item, itemIndex) => (
                  <Accordion
                    toggle={
                      <Heading2 as="span">
                        <AnimateSplitText>{item.title}</AnimateSplitText>
                      </Heading2>
                    }
                    border={itemIndex < items.length - 1}
                  >
                    <RichText content={item.richContent} maxWidth={25.3} />
                  </Accordion>
                ))
              )}
            </BenefitsItems>
          </GridItem>
        </Grid>

        <Spacer size={[60, 214]} />
      </BenefitsWrapper>
    </Container>
  </BenefitsMain>
)

Benefits.propTypes = {
  title: PropTypes.string,
  text: PropTypes.object,
  items: PropTypes.array,
}

export default Benefits
