import styled from 'styled-components'
import { mq } from '../../styles/vars/media-queries.style'

export const FeaturedInMain = styled.div`
  text-align: center;
`

export const FeaturedInBrands = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${mq.tabletL} {
    flex-wrap: wrap;
    flex-direction: row;
  }

  span {
    ${mq.tabletL} {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
`

export const FeaturedInLogo = styled.img`
  flex: 1;
  margin-bottom: 4.4rem;
  max-height: 8rem;
  max-width: 15rem;
  width: 100%;

  ${mq.tabletP} {
    max-width: ${props => `${props.maxWidth / 10}rem`};
  }
`
