import React from 'react'
import PropTypes from 'prop-types'
import { FeaturedInBrands, FeaturedInLogo, FeaturedInMain } from './index.style'
import Container from '../Container'
import Grid from '../Grid'
import GridItem from '../GridItem'
import { TextBodyLarge } from '../TextStyles'
import AnimateSplitText from '../animation/AnimateSplitText'
import Spacer from '../Spacer'
import AnimateSlideIn from '../animation/AnimateSlideIn'

const FeaturedIn = ({ title, brands }) => (
  <FeaturedInMain>
    <Container>
      <TextBodyLarge>
        <AnimateSplitText>{title}</AnimateSplitText>
      </TextBodyLarge>

      <Spacer size={46} />

      <Grid>
        <GridItem>
          <FeaturedInBrands>
            {React.Children.toArray(
              brands.map((brand, brandIndex) => (
                <AnimateSlideIn delay={brandIndex * 0.05}>
                  <FeaturedInLogo
                    src={brand.file.url}
                    alt={brand.description}
                    maxWidth={169}
                  />
                </AnimateSlideIn>
              ))
            )}
          </FeaturedInBrands>
        </GridItem>
      </Grid>
    </Container>

    <Spacer size={[50, 110]} />
  </FeaturedInMain>
)

FeaturedIn.propTypes = {
  title: PropTypes.string,
  brands: PropTypes.array,
}

export default FeaturedIn
